define('huyang-common/components/file-upload', ['exports', 'huyang-common/templates/components/file-upload', 'npm:@sentry/browser'], function (exports, _fileUpload, _browser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _fileUpload.default,

        inputId: 'fileUploader',
        inputName: 'files[]',
        multiple: false,
        acceptPattern: '/.+/', // /(\.|\/)(gif|jpe?g|png)$/i
        completedFiles: [],
        pendingFiles: [],
        failedFiles: [],
        showFileList: true,
        showProgress: true,
        inProgress: false,
        showPreview: false,
        fullWindow: false,
        statusIcons: {
            'pending': 'fa-circle-o',
            'failed': 'fa-exclamation',
            'completed': 'fa-check-circle-o'
        },
        api: Ember.inject.service(),

        setup: function () {
            var self = this;
            var inputId = self.get('inputId');
            if (!inputId) {
                return;
            }

            self.reset();

            var dropArea = self.$('.dropzone-overlay');
            if (!self.get('fullWindow')) {
                dropArea = self.$('.drag-and-drop');
            }

            self.$('#' + self.get('inputId')).fileupload({
                dataType: 'json',
                self: self.get('api').getHeaders(),
                url: self.get('fullUrl'),
                acceptFileTypes: self.get('acceptPattern'),
                dropZone: dropArea,

                formData: function formData() {
                    return [{ name: self.get('hiddenName'), value: self.get('hiddenValue') }];
                },

                done: function done(e, data) {
                    if (self.uploadCallback && typeof self.uploadCallback === 'function') {
                        self.uploadCallback(data);
                    }
                    var file_id;
                    if (data.result.meta) {
                        file_id = data.result.meta.uuid;
                        self.markCompleted(data.result.meta.filename);
                    }
                    self.sendAction('fileUploaded', file_id, data);

                    self.set('progress', 0);
                    if (self.get('showPreview')) {
                        var img = '<img src="' + URL.createObjectURL(data.files[0]) + '"/>';
                        if (self.get('multiple')) {
                            Ember.$('#fileUploadPreview').append(img);
                        } else {
                            Ember.$('#fileUploadPreview').html(img);
                        }
                    }
                },

                fail: function fail(e, data) {
                    self.set('inProgress', false);
                    self.set('progress', 0);
                    self.sendAction('failed', data.errorThrown);
                    console.log('error: ' + data.errorThrown);
                    self.markFailed(data.files[0].name);
                    _browser.default.captureException(e);
                },

                drop: function drop(e, data) {
                    Ember.$.each(data.files, function (index, file) {
                        console.log('Dropped file: ' + file.name);
                    });
                },

                add: function add(e, data) {
                    console.log('add', e, data);
                    console.log(data.files.length + ' files: ', data.files);

                    // file objects don't have unique enough data to accurately
                    // identify them, so add an id which will be used in 'fileAdded'
                    _.each(data.files, function (file) {
                        file.id = +(moment().unix() + Math.floor(Math.random() * 9999).toString());
                    });

                    self.sendAction('fileAdded', data, inputId);
                    self.set('inProgress', true);
                    if (_.isUndefined(self.get('pendingFiles')) || self.get('pendingFiles').length === 0) {
                        self.set('progress', 0);
                    }

                    _.each(data.files, function (file) {
                        // re-upload same file
                        if (_.contains(self.get('completedFiles', file.name))) {
                            self.set('completedFiles', _.without(self.get('completedFiles'), file.name));
                        }

                        self.get('pendingFiles').push(file.name);
                    });

                    data.process().done(function () {
                        data.submit();
                    });
                },

                progressall: function progressall(e, data) {
                    self.set('progress', parseInt(data.loaded / data.total * 100, 10));
                }
            });
        }.on('didInsertElement'),

        markFailed: function markFailed(filename) {
            this.set('pendingFiles', _.without(this.get('pendingFiles'), filename));
            this.set('inProgress', this.get('pendingFiles').length > 0);
            this.set('failedFiles', this.get('failedFiles').concat(filename));
        },

        markCompleted: function markCompleted(filename) {
            this.set('pendingFiles', _.without(this.get('pendingFiles'), filename));
            this.set('inProgress', this.get('pendingFiles').length > 0);
            console.log('markCompleted: set inProgress to ' + this.get('inProgress'));
            this.set('completedFiles', this.get('completedFiles').concat(filename));
        },

        hasCompletedFiles: function () {
            return this.get('completedFiles').length > 0;
        }.property('completedFiles.[]'),

        pendingFileCount: function () {
            return this.get('pendingFiles').length;
        }.property('pendingFiles.[]'),

        fileCount: function () {
            return this.get('completedFiles').length;
        }.property('completedFiles.[]'),

        fileList: function () {
            var self = this;
            var files = [];

            this.get('pendingFiles').forEach(function (filename) {
                files.push({ name: filename, statusClassName: self.get('statusIcons.pending') });
            });

            this.get('completedFiles').forEach(function (filename) {
                files.push({ name: filename, statusClassName: self.get('statusIcons.completed') });
            });

            this.get('failedFiles').forEach(function (filename) {
                files.push({ name: filename, statusClassName: self.get('statusIcons.failed') });
            });

            return files;
        }.property('pendingFiles.[]', 'completedFiles.[]', 'failedFiles.[]'),

        hasProgress: function () {
            return this.get('showProgress') && this.get('inProgress');
        }.property('showProgress', 'inProgress'),

        progressWidth: function () {
            var progress = this.get('progress') || 0;
            return new Ember.String.htmlSafe('width: ' + progress + '%');
        }.property('progress'),

        reset: function () {
            // if hidden value changes, clear list of files
            this.set('completedFiles', []);
            this.set('pendingFiles', []);
            this.set('failedFiles', []);
        }.observes('hiddenValue'),

        fullUploadUrl: function () {
            return this.get('api').getHost() + this.get('uploadUrl');
        }.property('uploadUrl'),

        actions: {
            removeFile: function removeFile(filename) {
                var self = this;
                ['completed', 'pending', 'failed'].forEach(function (a) {
                    var arrayName = a + 'Files';
                    var files = self.get(arrayName);
                    if (files.indexOf(filename) >= 0) {
                        self.set(arrayName, _.without(files, filename));
                    }
                });
                // action recipient should remove from the database
                this.sendAction('removed', filename);
            }
        }
    });
});